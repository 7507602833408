export default class Scrollino {
	constructor() {
		// Init
		this.currentScrollTop = 0;
		this.prevScrollTop = 0;
		this.currentDir = false;

		// Listen scroll
		window.addEventListener(
			"scroll",
			() => {
				this.currentScrollTop = window.pageYOffset || document.documentElement.scrollTop;
				const scrolled = this.currentScrollTop > this.prevScrollTop;
				// On down scroll
				if (!this.currentDir && scrolled) {
					this.prevScrollTop = this.currentScrollTop;
					document.body.setAttribute("data-scroll", "down");
					this.currentDir = true;

					// On up scroll
				} else if (this.currentDir && this.currentScrollTop < this.prevScrollTop) {
					this.prevScrollTop = this.currentScrollTop;
					document.body.setAttribute("data-scroll", "up");
					this.currentDir = false;
				}
				this.prevScrollTop = this.currentScrollTop;
			},
			false,
		);
	}
}
