import '../styles/index.scss';

import Swiper, { Navigation, EffectFade } from "swiper";
import Scrollino from './Scrollino';

// Scroll detection
new Scrollino();

// Products swiper
new Swiper('.products', {
    speed: 500,
    slidesPerView: 1,
    navigation: {
      nextEl: '.products_next',
      prevEl: '.products_prev',
    },
    breakpoints: {
        768: {
            slidesPerView: 2,
        },
        1024: {
            slidesPerView: 3,
        },
        1280: {
            slidesPerView: 4,
        }
    },
    modules: [ Navigation ]
});

// Slider swiper
new Swiper('.slider', {
    speed: 500,
    slidesPerView: 1,
    navigation: {
      nextEl: '.slider_next',
      prevEl: '.slider_prev',
    },
    effect: 'fade',
    fadeEffect: {
      crossFade: true
    },
    loop: true,
    modules: [ Navigation, EffectFade ]
});